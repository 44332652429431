<template>
  <v-dialog v-if="dialog" v-model="dialog" max-width="1200px" scrollable>
    <v-card>
      <v-card-title>
        <card-title-with-back-button
          :title="`Par Biomagnético: ${selectedPar.name}`"
          @back="close"
        />
      </v-card-title>
      <v-card-text>
        <tracking-impaction-point-input-sides
          :value="diagnosePar.pointsSides"
          :tracking-point="selectedTrackingPoint"
          :impaction-point="selectedImpactionPoint"
          class="grey"
          @input="
            (value) => setValuesDiagnosePar({ value, property: 'pointsSides' })
          "
        />
        <div class="pa-5">
          <div class="d-flex align-baseline">
            <property-value
              property="Tipo do par"
              :value="selectedPar.type.name"
              class="mr-1"
            />
            <explanation-icon
              :explanation="selectedPar.type.explanation"
              :name="selectedPar.type.name"
            />
          </div>
          <div class="d-flex align-baseline">
            <property-value
              property="Descrição"
              :value="selectedPar.description"
              class="mr-1"
            />
          </div>

          <div>
            <select-par-properties
              :value="diagnosePar.symptoms"
              label="Sintomas"
              :items="selectedPar.symptoms"
              @input="
                (value) => setValuesDiagnosePar({ value, property: 'symptoms' })
              "
            />
          </div>

          <div class="my-4">
            <select-par-properties
              :value="diagnosePar.pathogens"
              label="Patógenos"
              :items="selectedPar.pathogens"
              @input="
                (value) =>
                  setValuesDiagnosePar({ value, property: 'pathogens' })
              "
            />
          </div>

          <v-switch
            :value="diagnosePar.hidePatient"
            label="Esconder ao paciente na diagnose"
            @change="
              (value) =>
                setValuesDiagnosePar({ value, property: 'hidePatient' })
            "
          />
          <v-switch
            :value="diagnosePar.patientImpactAtHome"
            label="Recomendar ao paciente impactar em casa"
            @change="
              (value) =>
                setValuesDiagnosePar({ value, property: 'patientImpactAtHome' })
            "
          />
          <div class="my-5">
            <recommendations :recommendations="selectedPar.recommendations" />
          </div>

          <comments
            :value="diagnosePar.comments"
            @input="
              (value) => setValuesDiagnosePar({ value, property: 'comments' })
            "
          />

          <div class="mt-4">
            <check-pares-buttons
              :value="selectedPar.checkPares"
              :can-open="isParAddedToDiagnose"
              @click="clickAnyParToCheck"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column full-width">
          <add-to-diagnose-button
            :is-added-to-diagnose="isParAddedToDiagnose"
            @click="addToDiagnose"
          />
          <v-btn large class="mt-3" outlined color="black" block @click="close">
            <v-icon class="mr-1">arrow_back</v-icon>
            Voltar aos Pontos
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ParDialog",
  components: {
    "check-pares-buttons": () =>
      import("../../../check-pares/presentation/components/CheckParesButtons"),
    comments: () =>
      import("@/domains/therapy/shared/presentation/components/Comments"),
  },
  data: () => ({
    isParAddedToDiagnose: false,
    isRequiredFieldsFilled: false,
  }),
  computed: {
    selectedPar() {
      return this.$store.getters["pares/selectedPar"];
    },
    dialog: {
      get() {
        return this.$store.state.dialogs.pares.par;
      },
      set(value) {
        this.$store.commit("dialogs/setParDialog", value);
      },
    },
    selectedTrackingPoint() {
      return this.selectedPar.points?.trackingPoint;
    },
    selectedImpactionPoint() {
      return this.selectedPar.points?.impactionPoint;
    },
    diagnosePar() {
      return this.$store.state.pares.diagnosePar;
    },
    hasCheckPares() {
      return (
        !!this.selectedPar.checkPares && this.selectedPar.checkPares.length > 0
      );
    },
  },
  async beforeUpdate() {
    this.isParAddedToDiagnose = await this.$store.dispatch(
      "diagnosis/verifyIfExistsParInDiagnosis",
      this.selectedPar.id
    );
  },
  methods: {
    setValuesDiagnosePar({ value, property }) {
      this.$store.commit("pares/setValuesDiagnosePar", { value, property });
    },
    async addToDiagnose() {
      try {
        this.validateProperties();
        await this.$store.dispatch("pares/addDiagnoseParToDiagnose");
        this.$toasted.global.success({
          message: "Par adicionado ao diagnóstico.",
        });
        this.clearDiagnosePar();
        this.isParAddedToDiagnose = true;
        if (!this.hasCheckPares) {
          this.close();
        }
      } catch (error) {
        this.$toasted.global.error({ message: error.message });
      }
    },
    async clickAnyParToCheck() {
      if (!this.isParAddedToDiagnose) {
        this.$warningAlert({
          message:
            "Antes de checar os pares, adicione o par atual ao diagnóstico.",
        });
      }
    },
    clearDiagnosePar() {
      this.$store.commit("pares/clearDiagnosePar");
    },
    close() {
      this.dialog = false;
    },
    validateProperties() {
      const {
        pointsSides: { trackingPointSide, impactionPointSide },
      } = this.diagnosePar;

      const {
        points: { trackingPoint, impactionPoint },
      } = this.selectedPar;

      const errorMessage =
        "Nenhum lado do ponto selecionado. Selecione ao menos um lado para o ponto.";
      if (trackingPoint.hasSides && !trackingPointSide) {
        throw new Error(errorMessage);
      }

      if (impactionPoint.hasSides && !impactionPointSide) {
        throw new Error(errorMessage);
      }
    },
  },
};
</script>

<style scoped>
.full-width > * {
  width: 100%;
}
</style>
